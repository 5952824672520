import {makeStyles} from '@material-ui/core/styles'
import Menu, {MenuProps} from '@material-ui/core/Menu'
import Close from '@material-ui/icons/Close'
import AppTypographyLink from 'src/components/elements/AppTypographyLink'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_EN} from 'src/constants/locale'
import {sections} from './data'
import {Fragment} from 'react'
import AppTypography from 'src/components/elements/typography/AppTypography'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({breakpoints, spacing, shadows, palette}) => ({
    paper: {
      marginTop: spacing(2),
      background: palette.common.black,
      '& .MuiPopover-paper': {
        boxShadow: shadows[2],
      },
      [breakpoints.down('md')]: {
        maxHeight: '100% !important',
      },
      [breakpoints.between('xs', 'sm')]: {
        marginRight: spacing(2.5),
      },
      [breakpoints.up(800)]: {
        transform: 'translate(-10%, -30%) !important',
        width: '700px !important',
        transformOrigin: 'unset !important',
      },
    },
    closeIcon: {
      display: 'none',
      position: 'absolute',
      right: spacing(2),
      top: spacing(2),
      fontSize: 16,
      [breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  }),
  {index: 1},
)

export default function DiscoverPlacesMenu(
  props: {onClose: () => void} & MenuProps,
) {
  const {onClose} = props

  const classes = useStyles()

  return (
    <Fragment>
      <Content isSeo />
      <Menu
        classes={{
          paper: classes.paper,
          list: 'p-0',
        }}
        {...props}
      >
        <div className="p-4">
          <Content />
        </div>
        <Close className={classes.closeIcon} onClick={onClose} />
      </Menu>
    </Fragment>
  )
}

function Content(props: {isSeo?: boolean}) {
  const currentLocale = useCurrentLocale()

  const hiddenStyle = props.isSeo ? {display: 'none'} : {}

  return (
    <div className="m-0 w-full max-h-full" style={hiddenStyle}>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {sections.map(({title, links}, index) => (
          <div key={index}>
            <AppTypography
              variant="caption"
              className="uppercase"
              component="span"
            >
              {title}
            </AppTypography>
            {links.map(({label, path, label_en, path_en}) => (
              <AppTypographyLink
                key={label}
                href={currentLocale === LOCALE_EN ? path_en : path}
                variant="body2"
                className="text-white mb-1"
              >
                {currentLocale === LOCALE_EN ? label_en : label}
              </AppTypographyLink>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
