import clsx from 'clsx'
import {useTranslation} from 'next-i18next'
import {makeStyles} from '@material-ui/core/styles'
import AppTypographyLink from 'src/components/elements/AppTypographyLink'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {useSmallSize} from 'src/hooks/screenSize'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_EN} from 'src/constants/locale'
import {dayjs} from 'src/utils/date'

const rightLinks = [
  {
    label: 'terms_and_conditions',
    path: '/conditions-d-utilisation',
    path_en: '/terms-of-use',
  },
  {
    label: 'about_us',
    path: '/aproposdenous',
    path_en: '/about-us',
  },
  {
    label: 'careers',
    path: '/carrieres',
    path_en: '/career',
  },
  {
    label: 'Blog',
    path: '/blog',
    path_en: '/blog',
  },
  {
    label: 'help',
    path: 'https://help.monsieurchalets.com',
    path_en: 'https://help.monsieurchalets.com',
  },
  {
    label: 'cottages_for_sale',
    path: '/chalets-a-vendre',
    path_en: '/chalets-for-sale',
  },
  {
    label: 'owner_section',
    path: '/formulaires/section-proprietaire',
    path_en: '/formulaires/section-proprietaire',
  },
  {
    label: 'contact_us',
    path: '/contacteznous',
    path_en: '/contact-us',
  },
]

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({breakpoints, spacing}) => ({
    buttonText: {
      color: 'white  !important',
      opacity: '64%',
      marginRight: 10,
    },
    reservedContainer: {
      display: 'flex',
      gap: 1,
      [breakpoints.down('lg')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
    flexWrapper: {
      justifyContent: 'space-between',
      marginBottom: spacing(2),
      alignItems: 'center',
      display: 'flex',
      [breakpoints.down('lg')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
    links: {
      padding: spacing(0, 0),
      gap: spacing(3),
      paddingBottom: 0,
      marginTop: 0,
      alignItems: 'center',
      display: 'flex',
      [breakpoints.down('lg')]: {
        gap: spacing(1.5),
        marginTop: spacing(2),
        marginBottom: spacing(6), //on page with bottom cta, we need spaces
      },
      [breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
    linksLessGap: {
      gap: spacing(1),
    },
    link: {
      color: 'white',
      opacity: '64%',
    },
  }),
  {index: 1},
)

const currentYear = dayjs().format('YYYY')

export default function SecondaryFooter() {
  const classes = useStyles()
  const isSmallSize = useSmallSize()
  const {t} = useTranslation('footer')
  const currentLocale = useCurrentLocale()

  return (
    <>
      <div className={clsx(classes.flexWrapper, isSmallSize ? 'gap-16' : '')}>
        <div className={classes.reservedContainer}>
          <AppTypography
            className={classes.buttonText}
            variant="body"
            neutralColor={600}
          >
            {`© ${currentYear} - MonsieurChalets.com. ${t(
              'all_rights_reserved',
            )}`}
          </AppTypography>
          {/*  <div className={clsx(classes.links, classes.linksLessGap)}>
            {links.map(({path, label}, index) => (
              <Fragment key={index}>
                <AppTypographyLink
                  key={index}
                  href={path}
                  className={classes.link}
                  color="textPrimary"
                  variant="body2"
                >
                  {t(label)}
                </AppTypographyLink>
                {!isSmallSize && links.length - 1 !== index && <> | </>}
              </Fragment>
            ))}
          </div> */}
        </div>

        <div className={clsx(classes.links)}>
          {rightLinks.map(({path, path_en, label}, index) => (
            <AppTypographyLink
              key={index}
              href={currentLocale === LOCALE_EN ? path_en : path}
              className={classes.link}
              color="textPrimary"
              variant="body2"
            >
              {t(label, {ns: 'common'})}
            </AppTypographyLink>
          ))}
        </div>
      </div>
    </>
  )
}
